jQuery.fn.myBootgrid = function(options){
    var $this = this;
    var attr = this.attr('id');
    var id = "";
    if(attr != null && attr.substr(0,1) != '#'){
        id = "#";
    }
    id += attr;

    var setting = BootGridBasicSetting(id);

    setting = jQuery.extend(setting, options);

    BootgridSearchField(id, setting.search_arr);

    return this.bootgrid(setting).bootgrid('sort', setting.sort);


    function BootgridSearchField(id, arr){
        var headerId = id+'-header';
        jQuery(id).on("initialized.rs.jquery.bootgrid", function(){

            //add select box of searching criteria
            var $sel = jQuery(headerId).find("select[name=search]");
            var $search_txtbox = jQuery(headerId).find('.search-field');
            if($sel.length == 0){
                $sel = jQuery('<select name="search" class="form-control">');
                jQuery('<div class="search">').append($sel).prependTo(headerId + ' .actionBar');
                jQuery(arr).each(function() {
                    $sel.append(jQuery("<option>").attr('value',this.val).text(this.text));
                });
                jQuery(headerId + ' span.glyphicon-search').remove();

                //select field on change trigger reload table
                $sel.change(function(){
                    if($search_txtbox.val().trim() !=""){
                        $this.bootgrid('reload');
                    }
                });
            }
        });
    }
    function BootGridBasicSetting(id){
        return {
            ajax: true,
            statusMapping: {0:"",1:"",2:"",3:""},
            multiSort: true,
            rowCount: [10, 25, 50, -1],
            searchSettings: {
                delay: 250,
                characters: 2
            },
            requestHandler: function (req){
                var val = jQuery(id+'-header').find("select[name=search]").val()
                req.searchField = val;
                return req;
            },
            responseHandler: function(res){
                if(res.error) msgBox(res.error);
                return res;
            }
        }
    }
};