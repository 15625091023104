function getDialog(options){
    var $dialog;
    if(options.id){
        $dialog = $("#"+options.id);
        if($dialog.length == 0){
            $dialog = newDialog(options);
            $('body').append($dialog);
            $dialog = $dialog.dialog({
                autoOpen: false,
                modal: true,
                width: options.width,
                height: options.height,
                position: { my: "bottom", at: "top", of: options.element },
                close: options.onClose
            });
        }else{
            $dialog.html(options.content);
        }
    }

    return $dialog;
}

function newDialog(options){
    var $dialog = $('<div></div>');

    if(options && options.id){
        $dialog.attr('id', options.id);
    }
    
    if(options && options.title){
        $dialog.attr('title', options.title);
    }
    
    $dialog.html(options.content);
    
    return $dialog;
}