function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

function getRetrievingType(type){
    switch (parseInt(type)){
        case 1:
            return 'Item-out';
        case 2:
            return 'Pallet-out';
        case 3:
            return 'Piece-out';
        case 4:
            return 'Bundle-out';
        default:
            return "";
    }
}
