function msgBox(msg){
    alertModal({
        title: lang.get('common.alert'),
        msg: msg,
        cancel_button: false,
    });
}

function alertModal(params){
    var $modal = getModal(params);
    params.modal = $modal;
    showModal(params);
}

function showModal(params){
    var $modal = params.modal;
    var $title = $modal.find('.modal-title');
    var $msg = $modal.find('.modal-msg');
    var $ok = $modal.find(".modal-ok");
    var $cancel = $modal.find(".modal-cancel");
    var $footer = $modal.find(".modal-footer");

    params = $.extend({
        //default options
        cancel_button: false,
        footer: true,
        hideAfterOK: true
    }, params);

    var id = params.id;
    var title = params.title;
    var msg = params.msg;
    var ajax = params.ajax;
    var footer = params.footer;
    var cancel_button = params.cancel_button;
    var ok_callback = params.ok_callback;
    var modalHideCallback = params.modalHideCallback;
    var modalShowCallback = params.modalShowCallback;
    var modalShownCallback = params.modalShownCallback;
    var hideAfterOK = params.hideAfterOK;

    if(ajax){
        $.ajax({
            type: "POST",
            url: ajax.url,
            data: ajax.data,
            success: function(result){
                //console.log(result);
                if(result){
                    if(result.status == 200){
                        $msg.html(result.data);
                    }
                }
            },
            dataType: 'json',
            complete: function(){

            }
        });
    }else{
        $msg.html(msg);
    }

    if(cancel_button){
        $cancel.show();
    }else{
        $cancel.hide();
    }

    $title.html(title);

    if(footer === false){
        $footer.remove();
    }

    if(ok_callback != null){
        $ok.on('click', function(e){
            if(hideAfterOK) {
                $modal.modal('hide');
            }
            ok_callback($modal);
        });
    }else{
        $ok.on('click', function(e){
            $modal.modal('hide');
        });
    }

    $modal.on('show.bs.modal', function(){
        if(modalShowCallback){
            modalShowCallback($modal);
        }
    });

    $modal.on('shown.bs.modal', function(){
        if(modalShownCallback){
            modalShownCallback($modal);
        }
    });

    $modal.on('hide.bs.modal', function(){
        if(modalHideCallback){
            modalHideCallback($modal);
        }
    });

    $modal.on('hidden.bs.modal', function(){
//        $title.html('');
//        $msg.html('');
//        $ok.off('click');
//        $modal.off('show.bs.modal');
        $modal.remove();
    });

    $modal.modal('show');
}

function getModal(options){
    var $modal;
    if(options.id){
        $modal = $("#"+options.id);
        if($modal.length == 0){
            $modal = newModal(options.id);
        }
    }else{
        $modal = newModal();
    }

    $('body').append($modal);
    if(options){
        if(options.width){
            $modal.find('.modal-dialog').css('max-width', options.width);
        }
    }

    return $modal;
}

function newModal(id){
    var $modal = $('<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="alert-modalLabel" aria-hidden="true"></div>');

    if(id){
        $modal.attr('id', id);
    }

    $modal.html('<div class="modal-dialog" role="document" style="overflow:scroll;"><div class="modal-content"><div class="modal-header"><h5 class="modal-title"></h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="modal-body"><p class="modal-msg"></p></div><div class="modal-footer"><button type="button" class="btn btn-secondary modal-cancel" data-dismiss="modal" id="">'+lang.get('common.close')+'</button><button type="button" class="btn btn-primary modal-ok">'+lang.get('common.ok')+'</button></div></div></div>');

    return $modal;
}
