$(function(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        statusCode: {
            401: function(){
                $message({
                    content: lang.get('common.login_first'),
                    btn1: function(e){
                        location.href = 'login';
                    }
                });
            },
            403: function(){
                $message({
                    content: lang.get('common.session_expired'),
                    btn1: function(e){
                        location.reload();
                    }
                });
            },
            500: function(){
                $message({
                    content: lang.get('common.something_wrong')
                });
            }
        }
    });

    // handle side menu
    var $sideMenuShadow = $('#sideMenuShadow'),
        $toggleSideMenu = $('#toggleSideMenu'),
        $sideMenu = $('#sideMenu'),
        $closeSizeMenu = $('#closeSizeMenu');
    var isShowSizeMenu = false;

    $toggleSideMenu.click(handleSideMenu);
    $closeSizeMenu.click(handleSideMenu);
    $sideMenuShadow.click(handleSideMenu);

    function handleSideMenu() {
        if (!isShowSizeMenu) {
            $sideMenuShadow.show();
            $sideMenu.css({right: 0, opacity: 1});
        } else {
            $sideMenu.css({ right: '-245px', opacity: 0 });
            $sideMenuShadow.hide();
        }
        isShowSizeMenu = !isShowSizeMenu;
    }

    $('.dropdown-btn').click(function() {
        $(this).next().slideToggle();
        $(this).toggleClass('dropdown-open');
    });
});


// dialog
$(function() {

    function message(params) {
        var obj = {
            title: '',
            content: '',
            btn1_text: lang.get('common.ok'),
            btn1_callback: function() {},
            btn2: false,
            btn2_text: lang.get('common.cancel'),
            btn2_callback: function() {},
        };

        $.extend(obj, params);

        var box = $('<div id="boxfulMessage"></div>');

        var content = $('<div class="content-wrap">' + obj.content + '</div>');
        var btnWrap = $('<div class="btn-wrap"></div>');

        var btn1 = $('<button class="btn-1">' + obj.btn1_text + '</button>');
        btnWrap.append(btn1);
        btn1.click(function() {
            obj.btn1_callback && obj.btn1_callback();
            box.remove();
        });

        if(params.btn2) {
            var btn2 = $('<button class="btn-2">' + obj.btn2_text + '</button>');
            btnWrap.prepend(btn2);
            btn2.click(function () {
                obj.btn2_callback && obj.btn2_callback();
                box.remove();
            });
        }

        box.append(content);
        box.append(btnWrap);
        $('body').append(box);

    }

    window.$message = message;
});

// loading
$(function() {
    $.extend({
        loading: function() {
            var loadShadow = $('<div class="loader-shadow"></div>');
            $('body').append(loadShadow);

            var loadBox = $('<div class="loader"></div>')
            $('body').append(loadBox);

            var loadBox = $('<div class="loader-msg">' + lang.get('common.loader_text') + '</div>')
            $('body').append(loadBox);
        },
        removeLoading: function() {
            $('.loader-msg').remove();
            $('.loader').remove();
            $('.loader-shadow').remove();
        }
    });
})

// button request for preventing duplicate request
$(function(){
    $.fn.buttonAjax = function(ajaxOption, doneCallback){
        var $this = $(this);

        if($this.data("is-request-running")){
            return;
        }

        $this.data("is-request-running", true);

        $.ajax(ajaxOption).done(function( data ) {
            doneCallback(data);
            $this.data("is-request-running", false);
        });
    };
});
